@import "../../styles/common-input-styles.scss";

.iconBlock {
  display: flex;
  align-items: center;
  height: 100%;
  padding-inline-end: 8px;
  padding-inline-start: 1px;

  background-color: inherit;
}

.childrenBlock {
  display: flex;
  align-items: center;
  padding: 2px 0px 2px 2px;
  background-color: inherit;
}

.prepend {
  display: flex;
  align-items: center;
  background-color: inherit;
}

.append {
  align-items: center;
  margin: 0;
  background-color: inherit;
}

.inputGroup {
  @include common-input-color-styles;

  width: 100%;

  display: flex;

  :global {
    .append {
      background: inherit;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #fff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
}
